import { lazy } from 'react';

import { Route } from 'react-router-dom';

import { type IRoutes } from '../@types';

// Error
const NotFound = lazy(() => import('../pages/error/not-found'));

// Auth
const Login = lazy(() => import('../pages/auth/login'));
const Register = lazy(() => import('../pages/auth/register'));
const WorkOrderReference = lazy(() => import('../pages/auth/work-order-reference'));
const AuthorizationAccepted = lazy(() => import('../pages/auth/authorization-accepted'));
const Payment = lazy(() => import('../pages/public/payment'));
const Transaction = lazy(() => import('../pages/public/transaction'));

// Public
const Home = lazy(() => import('../pages/public/home'));
const PrivacyPolicy = lazy(() => import('../pages/public/document/privacy-policy'));
const TermsConditions = lazy(() => import('../pages/public/document/terms-conditions'));
const Search = lazy(() => import('../pages/public/search'));
const SignIn = lazy(() => import('../pages/public/sign-in'));

// SuperAdmin
const SuperAdmin = lazy(() => import('../pages/super-admin'));
const vendorListing = lazy(() => import('../pages/super-admin/vendor-listing'));
const vendorInvoices = lazy(() => import('../pages/super-admin/vendor-invoices'));

// Admin
const Dashboard = lazy(() => import('../pages/admin'));
const Carriers = lazy(() => import('../pages/admin/carriers'));
const UserAccounts = lazy(() => import('../pages/admin/user-accounts'));
const BankAccounts = lazy(() => import('../pages/admin/bank-accounts'));
const WorkOrderTemplates = lazy(() => import('../pages/admin/work-order-templates'));
const CreateWorkOrderTemplate = lazy(() => import('../pages/admin/work-order-templates/create'));
const EditWorkOrderTemplate = lazy(() => import('../pages/admin/work-order-templates/edit'));
const Products = lazy(() => import('../pages/admin/invoices/products'));
const CustomFields = lazy(() => import('../pages/admin/invoices/custom-fields'));
const ProductsTaxes = lazy(() => import('../pages/admin/invoices/products-taxes'));
const CompanyInformation = lazy(() => import('../pages/admin/company-information'));
const AddTeammate = lazy(() => import('../pages/admin/user-accounts/add-teammate'));
const EditTeammate = lazy(() => import('../pages/admin/user-accounts/edit-teammate'));
const Services = lazy(() => import('../pages/super-admin/super-admin-services'));
// const Pay = lazy(() => import('../components/admin/invoice/invoice-flow/pay'));
const Customers = lazy(() => import('../components/admin/invoice/invoice-flow/customers'));
const CompanyData = lazy(() => import('../components/admin/invoice/invoice-flow/company-data'));
const CreateInvoice = lazy(() => import('../components/admin/invoice/invoice-flow/create-invoice'));
// const InvoiceCheckout = lazy(() => import('../components/admin/invoice/invoice-flow/invoice-checkout'));
// const CreditPayment = lazy(() => import('../components/admin/invoice/invoice-flow/credit-payment'));

const Deposit = lazy(() => import('../pages/admin/deposit'));
const Invoices = lazy(() => import('../pages/admin/invoices'));
const WorkOrders = lazy(() => import('../pages/admin/work-orders'));
const InstantPayout = lazy(() => import('../pages/admin/instant-payout'));
const NewWorkOrdersAuthorization = lazy(() => import('../pages/admin/work-orders/new-work-orders-authorization'));
const PaymentProcessed = lazy(() => import('../components/admin/invoice/invoice-flow/payment-processed'));

export const publicRoutes: IRoutes[] = [
  { path: '/', name: 'Home', component: Home },
  { path: '/search', name: 'Search', component: Search },
  { path: '/privacy-policy', name: 'privacy policy', component: PrivacyPolicy },
  { path: '/terms-conditions', name: 'terms conditions', component: TermsConditions },
  { path: '/sign-in', name: 'Sign In', component: SignIn },
];

export const authRoutes: IRoutes[] = [
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/work-order-reference', name: 'Work Order Reference', component: WorkOrderReference },
  { path: '/authorization-accepted', name: 'Search', component: AuthorizationAccepted },
  { path: '/payment/:id/:randomParam', name: 'Payment', component: Payment },
  // { path: '/transaction/:id/:randomParam', name: 'Transaction', component: Transaction },
  { path: '/payment/processed', name: 'Payment Processed', component: PaymentProcessed },
];

export const superAdmin: IRoutes[] = [
  { path: '', name: 'Super-Admin', component: SuperAdmin },
  { path: 'vendor-listing', name: 'vendors', component: vendorListing },
  { path: 'vendor-invoices', name: 'vendor invoices', component: vendorInvoices },
  { path: 'company-create', name: 'Company Information', component: CompanyInformation },
  { path: 'services', name: 'Services', component: Services },
  { path: 'company/create', name: 'Company Information', component: CompanyInformation },
  { path: 'dashboard/:companyId', name: 'Admin', component: Dashboard },
  { path: 'dashboard/:companyId/carriers', name: 'Company Carriers', component: Carriers },
  { path: 'dashboard/:companyId/user-accounts', name: 'User Accounts', component: UserAccounts },
  { path: 'dashboard/:companyId/user-accounts/add-teammate', name: 'Add New Teammate', component: AddTeammate },
  { path: 'dashboard/:companyId/user-accounts/edit-teammate/:id', name: 'Edit Teammate', component: EditTeammate },
  { path: 'dashboard/:companyId/bank-accounts', name: 'Bank Accounts', component: BankAccounts },
  { path: 'dashboard/:companyId/work-order-templates', name: 'Admin Work Orders', component: WorkOrderTemplates },
  { path: 'dashboard/:companyId/work-order-templates/create', name: 'CreateNewTeamlate', component: CreateWorkOrderTemplate },
  { path: 'dashboard/:companyId/work-order-templates/edit/:id', name: 'CreateNewTeamlate', component: EditWorkOrderTemplate },
  { path: 'dashboard/:companyId/invoices/products', name: 'Invoices Products', component: Products },
  { path: 'dashboard/:companyId/invoices/custom-fields', name: 'Invoices Custom Fields', component: CustomFields },
  { path: 'dashboard/:companyId/invoices/products-taxes', name: 'Invoices Products Taxes', component: ProductsTaxes },
  { path: 'dashboard/:companyId/company', name: 'Company Information', component: CompanyInformation },
  { path: 'dashboard/:companyId/invoices', name: 'Invoices', component: Invoices },
  { path: 'dashboard/:companyId/work-orders', name: 'Work Orders', component: WorkOrders },
  { path: 'dashboard/:companyId/deposits', name: 'Deposit', component: Deposit },
  {
    path: 'dashboard/:companyId/work-orders/new-authorization',
    name: 'Work Orders New Authorization',
    component: NewWorkOrdersAuthorization,
  },
  { path: 'dashboard/:companyId/instant-payout', name: 'Instant Payout', component: InstantPayout },
];

export const adminRoutes: IRoutes[] = [
  { path: 'dashboard', name: 'Admin', component: Dashboard },
  { path: 'dashboard/carriers', name: 'Company Carriers', component: Carriers },
  { path: 'dashboard/user-accounts', name: 'User Accounts', component: UserAccounts },
  { path: 'dashboard/user-accounts/add-teammate', name: 'Add New Teammate', component: AddTeammate },
  { path: 'dashboard/user-accounts/edit-teammate/:id', name: 'Edit Teammate', component: EditTeammate },
  { path: 'dashboard/bank-accounts', name: 'Bank Accounts', component: BankAccounts },
  { path: 'dashboard/work-order-templates', name: 'Admin Work Orders', component: WorkOrderTemplates },
  { path: 'dashboard/work-order-templates/create', name: 'CreateNewTeamlate', component: CreateWorkOrderTemplate },
  { path: 'dashboard/work-order-templates/edit/:id', name: 'CreateNewTeamlate', component: EditWorkOrderTemplate },
  { path: 'dashboard/invoices/products', name: 'Invoices Products', component: Products },
  { path: 'dashboard/invoices/products-taxes', name: 'Invoices Products Taxes', component: ProductsTaxes },
  { path: 'dashboard/invoices/custom-fields', name: 'Invoices Custom Fields', component: CustomFields },
  { path: 'dashboard/company', name: 'Company Information', component: CompanyInformation },
  { path: 'dashboard/deposits', name: 'Deposit', component: Deposit },
  { path: 'invoices', name: 'Invoices', component: Invoices },
  { path: 'work-orders', name: 'Work Orders', component: WorkOrders },
  {
    path: 'work-orders/new-authorization',
    name: 'Work Orders New Authorization',
    component: NewWorkOrdersAuthorization,
  },
  { path: 'instant-payout', name: 'Instant Payout', component: InstantPayout },
  { path: 'deposits', name: 'Deposit', component: Deposit },
];

export const employeeRouting: IRoutes[] = [
  { path: 'dashboard', name: 'Employee', component: Dashboard },
  { path: 'dashboard/work-orders', name: 'Work Orders', component: WorkOrders },
  { path: 'dashboard/deposits', name: 'Deposit', component: Deposit },
  {
    path: 'dashboard/work-orders/new-authorization',
    name: 'Work Orders New Authorization',
    component: NewWorkOrdersAuthorization,
  },
  { path: 'dashboard/invoices', name: 'Invoices', component: Invoices },
];

export const invoiceRoutes: IRoutes[] = [
  // { path: ':companyId/create/invoice-checkout/:id', name: 'invoice checkout', component: InvoiceCheckout },
  // { path: ':companyId/create/pay/:id', name: 'Create Invoice', component: Pay },
  { path: ':companyId/create/customers/:id', name: 'Location Settings', component: Customers },
  { path: ':companyId/create/:id', name: 'Location Settings', component: CreateInvoice },
  { path: ':companyId/create/company-data/:id', name: 'Location Settings', component: CompanyData },
  // { path: ':companyId/create/credit-payment/:id', name: 'Credit Payment', component: CreditPayment },
  { path: ':companyId/create/payment-processed/:id', name: 'Payment Processed', component: PaymentProcessed },
];

export const errorRoutes: IRoutes[] = [{ path: 'not-found', name: 'Not Found', component: NotFound }];

export const RouteRenderer = (route: IRoutes) =>
  route.component && <Route key={route.name} path={route.path} element={<route.component />} />;
