export enum RolesEnum {
  Employee = 'Employee',
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
}

export enum TransactionStatuses {
  Success = 'success',
  Pending = 'pending',
  Faild = 'faild',
  Refunded = 'refunded',
}

export enum PaymentMethod {
  Credit = 'Credit',
  Remote = 'Remote',
  Cash = 'Cash',
}

export enum TransactionTypes {
  Credit = 'credit',
  Remote = 'remote',
  Cash = 'cash',
}
